var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "error500"
  }, [_c('div', {
    staticClass: "error500-body-content"
  }, [_c('Card', [_c('div', {
    staticClass: "error500-body-content-title"
  }, [_vm._v(" 5"), _c('span', {
    staticClass: "error500-0-span"
  }, [_c('Icon', {
    attrs: {
      "type": "social-freebsd-devil"
    }
  })], 1), _c('span', {
    staticClass: "error500-0-span"
  }, [_c('Icon', {
    attrs: {
      "type": "social-freebsd-devil"
    }
  })], 1)]), _c('p', {
    staticClass: "error500-body-content-message"
  }, [_vm._v("Oops! the server is wrong")]), _c('div', {
    staticClass: "error500-btn-content"
  }, [_c('Button', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "size": "large",
      "type": "text"
    },
    on: {
      "click": _vm.goHome
    }
  }, [_vm._v("返回首页")]), _c('Button', {
    staticStyle: {
      "width": "200px",
      "margin-left": "40px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.backPage
    }
  }, [_vm._v("返回上一页")])], 1)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };